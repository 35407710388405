import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <section id="portfolio" class="portfolio">
      <div class="container">
        <div class="section-title">
          <h2>Feeling tipsy?</h2>
          <p>
            San Diego chebra tipsų kultūrą myli ir skatina, nes tai ne tik
            gražus gestas ar kultūrinis fenomenas – tai <b>AČIŪ</b> mūsų
            staff’ui!
          </p>
          <p>
            Here’s a <b>tip</b>: susirask kieno dėka tavo pasibūvimas buvo{" "}
            <i>(max)</i> puikus ir nepagailėk <i>(max)</i> tipsų! Karma ir mūsų
            staff’as atsidėkos.
          </p>
          <br /> San Diego palaiko Ukrainą, palaikyk ir tu! -{" "}
          <a href="#help" rel="noreferrer">
            Aukoti
          </a>
          <a href="#help" rel="noreferrer">
            <img alt="" src="images/slava.png" width="40" height="30" />
          </a>
        </div>

        <div class="section-title">
          <img src="images/bar.svg" class="filter-icon" alt="" />
          <h3>Salė</h3>
        </div>
        <div class="row portfolio-container">
          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Urtė</h4>
              </div>
              <img src="images/chebra/Urte.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/urtiniozas" rel="noreferrer" id="tips_urte">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@urtiniozas");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @urtiniozas{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Maksas</h4>
              </div>
              <img src="images/chebra/Maxas.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/maksimg71" rel="noreferrer" id="tips_maksas">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@maksimg71");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @maksimg71{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Agnė</h4>
              </div>
              <img src="images/chebra/Agne_new.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/liaucyte" rel="noreferrer" id="tips_agne">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@liaucyte");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @liaucyte{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Dominykas</h4>
              </div>
              <img src="images/chebra/dominykas_2.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/dpuodas" rel="noreferrer" id="tips_dom2">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@dpuodas");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @dpuodas{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Greta</h4>
              </div>
              <img src="images/chebra/greta.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/greta08d" rel="noreferrer" id="tips_greta">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@greta08d");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @greta08d{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Bazilė</h4>
              </div>
              <img src="images/chebra/bazile.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/baziliuk" rel="noreferrer" id="tips_bazile">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@baziliuk");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @baziliuk{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Mantas</h4>
              </div>
              <img src="images/chebra/mantas2.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/dvimdu" rel="noreferrer" id="tips_mantas2">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@dvimdu");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @dvimdu{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
                <i class="extra-info"></i>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Neila</h4>
              </div>
              <img src="images/chebra/neila.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/neilagos" rel="noreferrer" id="tips_Neila">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@neilagos");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @neilagos{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          
          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Ieva</h4>
              </div>
              <img src="images/chebra/ieva.png?V3" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/ievaiw5yb" rel="noreferrer" id="tips_Ieva">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@ievaiw5yb");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @ievaiw5yb{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

         
          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Dominykas</h4>
              </div>
              <img src="images/chebra/dominykas.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/kramis13" rel="noreferrer" id="tips_Dominykas">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@kramis13");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @kramis13{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Giedrius</h4>
              </div>
              <img src="images/chebra/giedrius_new.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/meshkafonas" rel="noreferrer" id="tips_giedrius">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@meshkafonas");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @meshkafonas{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Guru</h4>
              </div>
              <img src="images/chebra/Guru.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/eglevaic" rel="noreferrer" id="tips_guru">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@eglevaic");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @eglevaic{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
                <i class="extra-info">
                  Eglės Revolut - parašykit, kam tipsai.
                  <br />
                  Guru renka tipsus ne sau - už surinktą sumą kiti keturkojai
                  baro lankytojai džiaugsis skanėstais!
                </i>
              </div>
            </div>
          </div>
        </div>

        <div class="section-title">
          <br />
          <img src="images/food.svg" class="filter-icon" alt="" />
          <h3>Virtuvė</h3>
        </div>
        <div class="row portfolio-container">
          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Mantas</h4>
              </div>
              <img src="images/chebra/Mantas.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/mantas4s5" rel="noreferrer" id="tips_mantas">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@mantas4s5");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @mantas4s5{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Tadas</h4>
              </div>
              <img src="images/chebra/Tadas.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/tadaswgm7" rel="noreferrer" id="tips_tadas">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@tadaswgm7");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @tadaswgm7{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Renatas</h4>
              </div>
              <img src="images/chebra/renatas.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/renat2ue" rel="noreferrer" id="tips_renatas">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@renat2ue");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @renat2ue{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="section-title">
          <br />

          <img src="images/tvarka.svg" class="filter-icon" alt="" />
          <h3>Tvarkytojos</h3>
          <p>
            Jei stalai nušveisti iki veidrodžio skaidrumo, o grindys žvilga
            taip, kad jau lėksi pakartot Footloose scenarijaus, tai žinok, kad
            San Diego šeimai priklauso ir tvarkytojos, be kurių to nebūtų.
            Deja, jos prieigos prie Revoluto neturi, bet Eglė pasirūpins, kad jas
            pasiektų.
          </p>
        </div>
        <div class="row portfolio-container">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Jevgenija</h4>
              </div>
              <img src="images/chebra/Jevgenija.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://revolut.me/eglevaic" rel="noreferrer" id="tips_Alla">
                    <img
                      alt=""
                      src="images/tips_1.svg"
                      width="120"
                      height="120"
                    />
                  </a>
                </div>
                <div
                  class="revolut-name"
                  onClick={(event) => {
                    navigator.clipboard.writeText("@eglevaic");
                    event.target.childNodes[2].style.display = "none";
                    event.target.childNodes[3].style.display = "inline";
                  }}
                >
                  @eglevaic{" "}
                  <img
                    class="copy-icon"
                    src="images/copy.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                  <img
                    class="copied-icon"
                    src="images/ch.png"
                    alt=""
                    width="20"
                    height="20"
                  />
                </div>
                <i class="extra-info">Eglės Revolut - parašykit, kam tipsai.</i>
              </div>
            </div>
          </div>


        </div>
        <div class="section-title" id="help">
          <img
            src="images/slava.png"
            class=""
            alt=""
            width="40px"
            height="30px"
          />
          <h3>Parama</h3>
        </div>
        <div class="row portfolio-container">
          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>LGV</h4>
              </div>
              <img src="images/blue_yell.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://www.lgvfund.com" rel="noreferrer" id="lgv_link">
                    <img alt="" src="images/don.svg" width="120" height="120" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <div class="staff-name">
                <h4>Blue/Yellow</h4>
              </div>
              <img src="images/blue_yell.png" class="img-fluid" alt="" />
              <div class="portfolio-info">
                <div class="portfolio-links">
                  <a href="https://blue-yellow.lt" rel="noreferrer" id="blue_yellow_link">
                    <img alt="" src="images/don.svg" width="120" height="120" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <br />
        <br />
        <img
          src="images/Match.svg"
          class="filter-icon"
          alt=""
          width="25"
          height="25"
        />
      </div>
    </section>
  );
}

export default App;
